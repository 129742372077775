"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.SvgEventBusy = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _Svg = _interopRequireDefault(require("./Svg"));

var SvgEventBusy = function SvgEventBusy(_ref) {
  var size = _ref.size,
      props = (0, _objectWithoutProperties2.default)(_ref, ["size"]);
  return _react.default.createElement(_Svg.default, (0, _extends2.default)({
    viewBox: "0 0 24 24",
    height: size,
    width: size,
    fill: "currentcolor"
  }, props), _react.default.createElement("path", {
    d: "M9.3 18l2.4-2.4 2.4 2.4 1.1-1.1-2.4-2.4 2.4-2.4-1-1.1-2.4 2.4L9.3 11l-1.1 1.1 2.4 2.4-2.4 2.4L9.3 18zM19 4h-1V2h-2v2H8V2H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"
  }));
};

exports.SvgEventBusy = SvgEventBusy;
SvgEventBusy.isIcon = true;
SvgEventBusy.defaultProps = {
  size: 24
};
var _default = SvgEventBusy;
exports.default = _default;