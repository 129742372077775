"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.SvgBoxPlus = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _Svg = _interopRequireDefault(require("./Svg"));

var SvgBoxPlus = function SvgBoxPlus(_ref) {
  var size = _ref.size,
      props = (0, _objectWithoutProperties2.default)(_ref, ["size"]);
  return _react.default.createElement(_Svg.default, (0, _extends2.default)({
    viewBox: "0 0 24 24",
    height: size,
    width: size,
    fill: "currentcolor"
  }, props), _react.default.createElement("path", {
    d: "M6 5c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1H6zm0-2h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3zm7 4h-2v4H7v2h4v4h2v-4h4v-2h-4V7z"
  }));
};

exports.SvgBoxPlus = SvgBoxPlus;
SvgBoxPlus.isIcon = true;
SvgBoxPlus.defaultProps = {
  size: 24
};
var _default = SvgBoxPlus;
exports.default = _default;