"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.SvgChevronRight = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _Svg = _interopRequireDefault(require("./Svg"));

var SvgChevronRight = function SvgChevronRight(_ref) {
  var size = _ref.size,
      props = (0, _objectWithoutProperties2.default)(_ref, ["size"]);
  return _react.default.createElement(_Svg.default, (0, _extends2.default)({
    viewBox: "0 0 24 24",
    height: size,
    width: size,
    fill: "currentcolor"
  }, props), _react.default.createElement("path", {
    d: "M8 16.6l4.6-4.6L8 7.4 9.4 6l6 6-6 6L8 16.6z"
  }));
};

exports.SvgChevronRight = SvgChevronRight;
SvgChevronRight.isIcon = true;
SvgChevronRight.defaultProps = {
  size: 24
};
var _default = SvgChevronRight;
exports.default = _default;