"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.SvgDeparture = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _Svg = _interopRequireDefault(require("./Svg"));

var SvgDeparture = function SvgDeparture(_ref) {
  var size = _ref.size,
      props = (0, _objectWithoutProperties2.default)(_ref, ["size"]);
  return _react.default.createElement(_Svg.default, (0, _extends2.default)({
    viewBox: "0 0 24 24",
    height: size,
    width: size,
    fill: "currentcolor"
  }, props), _react.default.createElement("path", {
    d: "M2.5 19h19v2h-19v-2zm19.6-9.9c-.2-.8-1-1.3-1.8-1.1l-5.4 1.5L8 3.1l-1.9.5 4.1 7.2-5 1.3-1.9-1.6-1.5.4 1.8 3.2.8 1.3L6 15l5.3-1.4 4.3-1.2L21 11c.8-.2 1.3-1.1 1.1-1.9z"
  }));
};

exports.SvgDeparture = SvgDeparture;
SvgDeparture.isIcon = true;
SvgDeparture.defaultProps = {
  size: 24
};
var _default = SvgDeparture;
exports.default = _default;