"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.SvgKitchenette = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _Svg = _interopRequireDefault(require("./Svg"));

var SvgKitchenette = function SvgKitchenette(_ref) {
  var size = _ref.size,
      props = (0, _objectWithoutProperties2.default)(_ref, ["size"]);
  return _react.default.createElement(_Svg.default, (0, _extends2.default)({
    viewBox: "0 0 24 24",
    height: size,
    width: size,
    fill: "currentcolor"
  }, props), _react.default.createElement("path", {
    d: "M20 2H4c-.6 0-1 .4-1 1v3h18V3c0-.6-.4-1-1-1zM6 5c-.5 0-1-.5-1-1s.5-1 1-1 1 .5 1 1-.5 1-1 1zm4 0c-.5 0-1-.5-1-1s.5-1 1-1 1 .5 1 1-.5 1-1 1zm4 0c-.5 0-1-.5-1-1s.5-1 1-1 1 .5 1 1-.5 1-1 1zm4 0c-.5 0-1-.5-1-1s.5-1 1-1 1 .5 1 1-.5 1-1 1zM3 20c0 .6.4 1 1 1h1v1h2v-1h10v1h2v-1h1c.6 0 1-.4 1-1V7H3v13zM7 8h10v1H7V8zm-2 2h14v9H5v-9z"
  }));
};

exports.SvgKitchenette = SvgKitchenette;
SvgKitchenette.isIcon = true;
SvgKitchenette.defaultProps = {
  size: 24
};
var _default = SvgKitchenette;
exports.default = _default;