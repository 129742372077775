"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.SvgCreditCard = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _Svg = _interopRequireDefault(require("./Svg"));

var SvgCreditCard = function SvgCreditCard(_ref) {
  var size = _ref.size,
      props = (0, _objectWithoutProperties2.default)(_ref, ["size"]);
  return _react.default.createElement(_Svg.default, (0, _extends2.default)({
    viewBox: "0 0 24 24",
    height: size,
    width: size,
    fill: "currentcolor"
  }, props), _react.default.createElement("path", {
    d: "M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"
  }));
};

exports.SvgCreditCard = SvgCreditCard;
SvgCreditCard.isIcon = true;
SvgCreditCard.defaultProps = {
  size: 24
};
var _default = SvgCreditCard;
exports.default = _default;