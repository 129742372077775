import styled, { ThemeProvider } from 'styled-components';
import { space, width, color, textAlign, top, right, bottom, left, zIndex, themeGet, alignItems, justifyContent, flexWrap, flexDirection, textStyle, fontSize, fontWeight, lineHeight, borderRadius, borderColor, display } from 'styled-system';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'pcln-icons';

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var defineProperty = _defineProperty;

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      defineProperty(target, key, source[key]);
    });
  }

  return target;
}

var objectSpread = _objectSpread;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var taggedTemplateLiteral = _taggedTemplateLiteral;

var createMediaQuery = function createMediaQuery(n) {
  return "@media screen and (min-width:".concat(n, ")");
};

var addAliases = function addAliases(arr, aliases) {
  return aliases.forEach(function (key, i) {
    return Object.defineProperty(arr, key, {
      enumerable: false,
      get: function get() {
        return this[i];
      }
    });
  });
};

var breakpoints = [32, 40, 48, 64].map(function (n) {
  return n + 'em';
});
var mediaQueries = breakpoints.map(createMediaQuery);
var aliases = ['sm', 'md', 'lg', 'xl'];
addAliases(breakpoints, aliases);
addAliases(mediaQueries, aliases);
var space$1 = [0, 4, 8, 16, 32, 64, 128];
var font = "'Montserrat','Helvetica Neue',Helvetica,Arial,sans-serif";
var fontSizes = [12, 14, 16, 20, 24, 32, 40, 56, 72];
var medium = 500;
var bold = 700; // alias

var regular = medium; // styled-system's `fontWeight` function can hook into the `fontWeights` object

var fontWeights = {
  medium: medium,
  bold: bold,
  // alias
  regular: regular
};
var lineHeights = {
  standard: 1.5,
  display: 1.25
};
var letterSpacings = {
  normal: 'normal',
  caps: '0.025em'
};
var textStyles = {
  display8: {
    fontSize: fontSizes[8] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display
  },
  display7: {
    fontSize: fontSizes[7] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display
  },
  display6: {
    fontSize: fontSizes[6] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display
  },
  display5: {
    fontSize: fontSizes[5] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display
  },
  display4: {
    fontSize: fontSizes[4] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display
  },
  display3: {
    fontSize: fontSizes[3] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display
  },
  display2: {
    fontSize: fontSizes[2] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display
  },
  display1: {
    fontSize: fontSizes[1] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display
  },
  display0: {
    fontSize: fontSizes[0] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display,
    letterSpacing: letterSpacings.caps,
    textTransform: 'uppercase'
  },
  body2: {
    fontSize: fontSizes[2] + 'px',
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.standard
  },
  body1: {
    fontSize: fontSizes[1] + 'px',
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.standard
  },
  body0: {
    fontSize: fontSizes[0] + 'px',
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.standard
  } // color palette

};
var black = '#000';
var white = '#fff';
var text = '#001833';
var lightBlue = '#e8f2ff';
var blue = '#007aff';
var darkBlue = '#049';
var lightGray = '#f4f6f8';
var borderGray = '#c0cad5';
var gray = '#4f6f8f';
var darkGray = '#364049';
var lightGreen = '#ecf7ec';
var green = '#0a0';
var darkGreen = '#060';
var lightRed = '#fbebeb';
var red = '#c00';
var darkRed = '#800';
var orange = '#f68013';
var darkOrange = '#f06f20';
var lightPurple = '#f5ebfa';
var purple = '#70b';
var yellow = '#fedc2a';
var lightYellow = '#fff3c0'; // deprecated aliases

var darkPurple = purple;
var lightOrange = orange;
var colors = {
  black: black,
  white: white,
  text: text,
  blue: blue,
  lightBlue: lightBlue,
  darkBlue: darkBlue,
  gray: gray,
  lightGray: lightGray,
  borderGray: borderGray,
  darkGray: darkGray,
  green: green,
  lightGreen: lightGreen,
  darkGreen: darkGreen,
  red: red,
  lightRed: lightRed,
  darkRed: darkRed,
  orange: orange,
  darkOrange: darkOrange,
  purple: purple,
  lightPurple: lightPurple,
  yellow: yellow,
  lightYellow: lightYellow,
  // deprecated
  lightOrange: lightOrange,
  darkPurple: darkPurple
};
var colorStyles = {
  whiteOnText: {
    color: colors.white,
    backgroundColor: colors.text
  },
  whiteOnGray: {
    color: colors.white,
    backgroundColor: colors.gray
  },
  textOnLightGray: {
    color: colors.text,
    backgroundColor: colors.lightGray
  },
  whiteOnBlue: {
    color: colors.white,
    backgroundColor: colors.blue
  },
  blueOnLightBlue: {
    color: colors.blue,
    backgroundColor: colors.lightBlue
  },
  whiteOnGreen: {
    color: colors.white,
    backgroundColor: colors.green
  },
  greenOnLightGreen: {
    color: colors.green,
    backgroundColor: colors.lightGreen
  },
  whiteOnRed: {
    color: colors.white,
    backgroundColor: colors.red
  },
  redOnLightRed: {
    color: colors.red,
    backgroundColor: colors.lightRed
  },
  textOnOrange: {
    color: colors.text,
    backgroundColor: colors.orange
  },
  whiteOnPurple: {
    color: colors.white,
    backgroundColor: colors.purple
  },
  purpleOnLightPurple: {
    color: colors.purple,
    backgroundColor: colors.lightPurple
  },
  textOnWhite: {
    color: colors.text,
    backgroundColor: colors.white
  },
  grayOnWhite: {
    color: colors.gray,
    backgroundColor: colors.white
  },
  blueOnWhite: {
    color: colors.blue,
    backgroundColor: colors.white
  },
  greenOnWhite: {
    color: colors.green,
    backgroundColor: colors.white
  },
  redOnWhite: {
    color: colors.red,
    backgroundColor: colors.white
  },
  purpleOnWhite: {
    color: colors.purple,
    backgroundColor: colors.white
  },
  whiteOnDarkOrange: {
    color: colors.white,
    backgroundColor: colors.darkOrange
  }
};
colorStyles.info = colorStyles.textOnLightGray;
colorStyles.success = colorStyles.whiteOnGreen;
colorStyles.warning = colorStyles.textOnOrange;
colorStyles.danger = colorStyles.whiteOnRed; // styled-system's `borderRadius` function can hook into the `radii` object/array

var radii = [0, 2, 6];
var radius = '2px';
var maxContainerWidth = '1280px'; // boxShadows

var boxShadows = ["0 0 2px 0 rgba(0,0,0,.08),0 1px 4px 0 rgba(0,0,0,.16)", "0 0 2px 0 rgba(0,0,0,.08),0 2px 8px 0 rgba(0,0,0,.16)", "0 0 2px 0 rgba(0,0,0,.08),0 4px 16px 0 rgba(0,0,0,.16)", "0 0 2px 0 rgba(0,0,0,.08),0 8px 32px 0 rgba(0,0,0,.16)"]; // animation duration

var duration = {
  fast: "150ms",
  normal: "300ms",
  slow: "450ms",
  slowest: "600ms" // animation easing curves

};
var easeInOut = 'cubic-bezier(0.5, 0, 0.25, 1)';
var easeOut = 'cubic-bezier(0, 0, 0.25, 1)';
var easeIn = 'cubic-bezier(0.5, 0, 1, 1)';
var timingFunctions = {
  easeInOut: easeInOut,
  easeOut: easeOut,
  easeIn: easeIn // animation delay

};
var transitionDelays = {
  small: "60ms",
  medium: "160ms",
  large: "260ms",
  xLarge: "360ms"
};
var theme = {
  breakpoints: breakpoints,
  mediaQueries: mediaQueries,
  space: space$1,
  font: font,
  fontSizes: fontSizes,
  fontWeights: fontWeights,
  lineHeights: lineHeights,
  letterSpacings: letterSpacings,
  regular: regular,
  bold: bold,
  textStyles: textStyles,
  colors: colors,
  colorStyles: colorStyles,
  radii: radii,
  radius: radius,
  boxShadows: boxShadows,
  maxContainerWidth: maxContainerWidth,
  duration: duration,
  timingFunctions: timingFunctions,
  transitionDelays: transitionDelays
};

function _templateObject() {
  var data = taggedTemplateLiteral(["\n  ", " ", " ", " ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}
var Box = styled.div(_templateObject(), space, width, color, textAlign);
Box.displayName = 'Box';
Box.defaultProps = {
  theme: theme
};
Box.propTypes = objectSpread({}, space.propTypes, width.propTypes, color.propTypes, textAlign.propTypes);

function _templateObject$1() {
  var data = taggedTemplateLiteral(["\n  position: absolute;\n  ", " ", " ", " ", "\n  ", "\n"]);

  _templateObject$1 = function _templateObject() {
    return data;
  };

  return data;
}
var Absolute = styled(Box)(_templateObject$1(), top, bottom, left, right, zIndex);
Absolute.propTypes = objectSpread({}, top.propTypes, right.propTypes, bottom.propTypes, left.propTypes, zIndex.propTypes);
Absolute.displayName = 'Absolute';

function _templateObject$2() {
  var data = taggedTemplateLiteral(["\n  background-position: center;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-color: ", ";\n  ", " ", " ", ";\n"]);

  _templateObject$2 = function _templateObject() {
    return data;
  };

  return data;
}

var image = function image(props) {
  return props.image ? {
    backgroundImage: "url(".concat(props.image, ")")
  } : null;
};

var height = function height(props) {
  return props.height ? {
    height: props.height
  } : null;
};

var BackgroundImage = styled.div(_templateObject$2(), function (props) {
  return props.theme.colors.gray;
}, image, height, width);
var numberStringOrArray = PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]);
BackgroundImage.propTypes = {
  /** background-image url */
  image: PropTypes.string,
  width: numberStringOrArray
};
BackgroundImage.defaultProps = {
  theme: theme
};
BackgroundImage.displayName = 'BackgroundImage';

function _templateObject$3() {
  var data = taggedTemplateLiteral(["\n  border-radius: 99999px;\n  display: inline-block;\n  font-size: ", "px;\n  font-weight: 600;\n  text-transform: uppercase;\n  letter-spacing: ", ";\n  ", " ", " ", ";\n"]);

  _templateObject$3 = function _templateObject() {
    return data;
  };

  return data;
}

var type = function type(props) {
  var badgeColors = {
    blue: {
      backgroundColor: props.theme.colors.blue,
      color: props.theme.colors.white
    },
    lightBlue: {
      backgroundColor: props.theme.colors.lightBlue,
      color: props.theme.colors.darkBlue
    },
    green: {
      backgroundColor: props.theme.colors.green,
      color: props.theme.colors.white
    },
    lightGreen: {
      backgroundColor: props.theme.colors.lightGreen,
      color: props.theme.colors.darkGreen
    },
    red: {
      backgroundColor: props.theme.colors.red,
      color: props.theme.colors.white
    },
    lightRed: {
      backgroundColor: props.theme.colors.lightRed,
      color: props.theme.colors.darkRed
    },
    orange: {
      backgroundColor: props.theme.colors.orange,
      color: props.theme.colors.text
    },
    gray: {
      backgroundColor: props.theme.colors.gray,
      color: props.theme.colors.white
    },
    lightGray: {
      backgroundColor: props.theme.colors.lightGray,
      color: props.theme.colors.text
    }
  };
  return !(props.bg && props.color) && (badgeColors[props.bg] || badgeColors.lightGray);
};

var Badge = styled.div(_templateObject$3(), function (props) {
  return props.theme.fontSizes[0];
}, themeGet('letterSpacings.caps'), space, type, color);
Badge.displayName = 'Badge';
Badge.propTypes = objectSpread({}, space.propTypes, color.propTypes);
Badge.defaultProps = {
  px: 2,
  py: 1,
  theme: theme
};

function createCommonjsModule(fn, module) {
	return module = { exports: {} }, fn(module, module.exports), module.exports;
}

var _extends_1 = createCommonjsModule(function (module) {
function _extends() {
  module.exports = _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

module.exports = _extends;
});

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var objectWithoutPropertiesLoose = _objectWithoutPropertiesLoose;

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};
  var target = objectWithoutPropertiesLoose(source, excluded);
  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

var objectWithoutProperties = _objectWithoutProperties;

/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */

var REACT_STATICS = {
  childContextTypes: true,
  contextTypes: true,
  defaultProps: true,
  displayName: true,
  getDefaultProps: true,
  mixins: true,
  propTypes: true,
  type: true
};
var KNOWN_STATICS = {
  name: true,
  length: true,
  prototype: true,
  caller: true,
  arguments: true,
  arity: true
};
var isGetOwnPropertySymbolsAvailable = typeof Object.getOwnPropertySymbols === 'function';

var hoistNonReactStatics = function hoistNonReactStatics(targetComponent, sourceComponent, customStatics) {
  if (typeof sourceComponent !== 'string') {
    // don't hoist over string (html) components
    var keys = Object.getOwnPropertyNames(sourceComponent);
    /* istanbul ignore else */

    if (isGetOwnPropertySymbolsAvailable) {
      keys = keys.concat(Object.getOwnPropertySymbols(sourceComponent));
    }

    for (var i = 0; i < keys.length; ++i) {
      if (!REACT_STATICS[keys[i]] && !KNOWN_STATICS[keys[i]] && (!customStatics || !customStatics[keys[i]])) {
        try {
          targetComponent[keys[i]] = sourceComponent[keys[i]];
        } catch (error) {}
      }
    }
  }

  return targetComponent;
};

var mapProps = function mapProps(map) {
  return function (Component) {
    return hoistNonReactStatics(function (props) {
      return React.createElement(Component, map(props));
    }, Component);
  };
}; // Use this to mark props as deprecated

var deprecatedPropType = function deprecatedPropType(replacement) {
  return function (props, propName, componentName) {
    if (props[propName]) {
      return new Error("The `".concat(propName, "` prop is deprecated and will be removed in a future release. Please use `").concat(replacement, "` instead."));
    }
  };
};

function _templateObject$4() {
  var data = taggedTemplateLiteral(["\n  display: flex;\n  ", " ", " ", " ", " ", "\n  ", "\n  ", "\n"]);

  _templateObject$4 = function _templateObject() {
    return data;
  };

  return data;
}
var Flex = mapProps(function (_ref) {
  var wrap = _ref.wrap,
      align = _ref.align,
      justify = _ref.justify,
      props = objectWithoutProperties(_ref, ["wrap", "align", "justify"]);

  return objectSpread({
    flexWrap: wrap ? 'wrap' : undefined,
    alignItems: align,
    justifyContent: justify
  }, props);
})(styled.div(_templateObject$4(), space, width, color, alignItems, justifyContent, flexDirection, flexWrap));
Flex.defaultProps = {
  theme: theme
};
Flex.propTypes = objectSpread({}, space.propTypes, width.propTypes, color.propTypes, alignItems.propTypes, justifyContent.propTypes, flexWrap.propTypes, flexDirection.propTypes, {
  // deprecated
  wrap: deprecatedPropType('flexWrap'),
  align: deprecatedPropType('alignItems'),
  justify: deprecatedPropType('justifyContent')
});
Flex.displayName = 'Flex';

function _templateObject$5() {
  var data = taggedTemplateLiteral(["\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"]);

  _templateObject$5 = function _templateObject() {
    return data;
  };

  return data;
}
var caps = function caps(props) {
  return props.caps ? {
    textTransform: 'uppercase',
    letterSpacing: themeGet('letterSpacings.caps')(props)
  } : null;
};
var regular$1 = function regular$$1(props) {
  return props.regular ? {
    fontWeight: props.theme.regular
  } : null;
};
var bold$1 = function bold$$1(props) {
  return props.bold ? {
    fontWeight: props.theme.bold
  } : null;
};
var Text = mapProps(function (_ref) {
  var align = _ref.align,
      props = objectWithoutProperties(_ref, ["align"]);

  return objectSpread({
    textAlign: align
  }, props);
})(styled.div(_templateObject$5(), textStyle, fontSize, fontWeight, textAlign, lineHeight, space, color, caps, regular$1, bold$1));
Text.displayName = 'Text';
Text.propTypes = objectSpread({}, textStyle.propTypes, fontSize.propTypes, fontWeight.propTypes, textAlign.propTypes, lineHeight.propTypes, space.propTypes, color.propTypes, {
  caps: PropTypes.bool,
  regular: PropTypes.bool,
  bold: PropTypes.bool,
  align: deprecatedPropType('textAlign')
});
Text.defaultProps = {
  theme: theme
};
Text.span = Text.withComponent('span');
Text.p = Text.withComponent('p');
Text.s = Text.withComponent('s');

Icon.isIcon = true;

function _templateObject$6() {
  var data = taggedTemplateLiteral(["\n  -webkit-font-smoothing: antialiased;\n  display: inline-block;\n  vertical-align: middle;\n  text-align: center;\n  text-decoration: none;\n  font-family: inherit;\n  font-weight: 600;\n  line-height: 1.5;\n  cursor: pointer;\n  border-radius: ", ";\n  background-color: ", ";\n  color: ", ";\n  border-width: 0;\n  border-style: solid;\n\n  &:disabled {\n    opacity: 0.25;\n  }\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  ", " ", " ", ";\n"]);

  _templateObject$6 = function _templateObject() {
    return data;
  };

  return data;
}

var size = function size(props) {
  switch (props.size) {
    case 'small':
      return {
        fontSize: "".concat(props.theme.fontSizes[0], "px"),
        padding: '7px 12px'
      };

    case 'medium':
      return {
        fontSize: "".concat(props.theme.fontSizes[1], "px"),
        padding: '9.5px 18px'
      };

    case 'large':
      return {
        fontSize: "".concat(props.theme.fontSizes[2], "px"),
        padding: '12px 22px'
      };

    default:
      return {
        fontSize: "".concat(props.theme.fontSizes[1], "px"),
        padding: '9.5px 18px'
      };
  }
};

var Button = mapProps(function (_ref) {
  var fullWidth = _ref.fullWidth,
      props = objectWithoutProperties(_ref, ["fullWidth"]);

  return objectSpread({
    width: fullWidth ? 1 : undefined
  }, props);
})(styled.button(_templateObject$6(), function (props) {
  return props.theme.radius;
}, function (props) {
  return props.theme.colors.blue;
}, function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.disabled ? null : props.theme.colors.darkBlue;
}, width, size, space));
Button.propTypes = objectSpread({
  size: PropTypes.oneOf(['small', 'medium', 'large'])
}, width.propTypes, space.propTypes, {
  fullWidth: deprecatedPropType('width')
});
Button.defaultProps = {
  theme: theme
};
Button.displayName = 'Button';

function _templateObject$7() {
  var data = taggedTemplateLiteral(["\n  padding: 0;\n  background-color: transparent;\n  color: inherit;\n\n  &:hover {\n    background-color: transparent;\n  }\n  & > div {\n    display: flex;\n  }\n"]);

  _templateObject$7 = function _templateObject() {
    return data;
  };

  return data;
}
var TransparentButton = styled(Button)(_templateObject$7());

var IconButton = function IconButton(_ref) {
  var name = _ref.name,
      size = _ref.size,
      color$$1 = _ref.color,
      props = objectWithoutProperties(_ref, ["name", "size", "color"]);

  return React.createElement(TransparentButton, props, React.createElement("div", null, React.createElement(Icon, {
    name: name,
    size: size,
    color: color$$1
  })));
};

IconButton.displayName = 'IconButton';
IconButton.defaultProps = {
  theme: theme
};

var CloseButton = function CloseButton(props) {
  return React.createElement(IconButton, _extends_1({}, props, {
    name: "close"
  }));
};

CloseButton.defaultProps = {
  size: 24,
  title: 'close'
};
CloseButton.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
  title: PropTypes.string
};
CloseButton.displayName = 'CloseButton';

var Heading = Text.withComponent('h3');
Heading.displayName = 'Heading';
Heading.defaultProps = {
  textStyle: 'display4',
  m: 0,
  theme: theme
};
Heading.h1 = Heading.withComponent('h1');
Heading.h2 = Heading.withComponent('h2');
Heading.h3 = Heading.withComponent('h3');
Heading.h4 = Heading.withComponent('h4');
Heading.h5 = Heading.withComponent('h5');
Heading.h6 = Heading.withComponent('h6');

var bannerColors = {
  green: {
    backgroundColor: 'green',
    color: 'white',
    icon: 'success'
  },
  lightGreen: {
    backgroundColor: 'lightGreen',
    color: 'darkGreen',
    icon: 'success'
  },
  red: {
    backgroundColor: 'red',
    color: 'white',
    icon: 'warning'
  },
  lightRed: {
    backgroundColor: 'lightRed',
    color: 'darkRed',
    icon: 'warning'
  },
  orange: {
    backgroundColor: 'orange',
    color: 'white',
    icon: 'attention'
  },
  blue: {
    backgroundColor: 'blue',
    color: 'white',
    icon: 'information'
  },
  lightBlue: {
    backgroundColor: 'lightBlue',
    color: 'darkBlue',
    icon: 'information'
  }
};

var Banner = function Banner(props) {
  var bannerColor = bannerColors[props.bg] || {};
  var icon = props.iconName || bannerColor.icon;
  return React.createElement(Box, _extends_1({}, props, {
    bg: bannerColor.backgroundColor || props.bg,
    color: bannerColor.color || props.color
  }), React.createElement(Flex, {
    justifyContent: "space-between",
    alignItems: "flex-start"
  }, !!icon && !!props.showIcon && React.createElement(Icon, {
    name: icon,
    mr: 2,
    size: 24,
    mt: "-2px"
  }), React.createElement(Box, {
    w: 1
  }, React.createElement(Text, {
    textAlign: props.textAlign
  }, React.createElement(Heading.h5, null, props.header), React.createElement(Text.span, {
    fontSize: 1
  }, props.text), props.children)), !!props.onClose && React.createElement(CloseButton, {
    onClick: props.onClose,
    ml: 2,
    size: 24,
    title: "close",
    mt: "-2px"
  })));
};

Banner.displayName = 'Banner';
Banner.propTypes = {
  header: PropTypes.string,
  iconName: PropTypes.string,
  onClose: PropTypes.func,
  showIcon: PropTypes.bool,
  text: PropTypes.node,
  textAlign: PropTypes.string
};
Banner.defaultProps = {
  bg: 'green',
  textAlign: 'left',
  showIcon: true
};

function _templateObject$8() {
  var data = taggedTemplateLiteral(["\n  cursor: pointer;\n  text-decoration: none;\n  ", " &:hover {\n    text-decoration: underline;\n  }\n"]);

  _templateObject$8 = function _templateObject() {
    return data;
  };

  return data;
}
var Link = styled.a(_templateObject$8(), color);
Link.displayName = 'Link';
Link.propTypes = objectSpread({}, color.propTypes);
Link.defaultProps = {
  color: 'blue',
  theme: theme
};

function _templateObject$9() {
  var data = taggedTemplateLiteral(["\n  display: block;\n  color: inherit;\n  text-decoration: none;\n"]);

  _templateObject$9 = function _templateObject() {
    return data;
  };

  return data;
}
var BlockLink = styled(Link)(_templateObject$9());
BlockLink.displayName = 'BlockLink';

function _templateObject$a() {
  var data = taggedTemplateLiteral(["\n  ", " ", " ", ";\n"]);

  _templateObject$a = function _templateObject() {
    return data;
  };

  return data;
}

var boxShadow = function boxShadow(props) {
  var boxShadows$$1 = {
    sm: {
      'box-shadow': props.theme.boxShadows[0]
    },
    md: {
      'box-shadow': props.theme.boxShadows[1]
    },
    lg: {
      'box-shadow': props.theme.boxShadows[2]
    },
    xl: {
      'box-shadow': props.theme.boxShadows[3]
    }
  };
  return boxShadows$$1[props.boxShadowSize];
};

var boxBorder = function boxBorder(props) {
  return {
    border: props.borderWidth === 0 ? '0' : "".concat(props.borderWidth, "px solid ").concat(props.theme.colors[props.borderColor])
  };
};

var Card = styled(Box)(_templateObject$a(), boxShadow, boxBorder, borderRadius);
Card.propTypes = objectSpread({}, borderRadius.propTypes, {
  boxShadowSize: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  borderColor: PropTypes.string,
  borderWidth: PropTypes.oneOf([0, 1, 2])
});
Card.defaultProps = {
  borderColor: 'borderGray',
  borderRadius: 1,
  borderWidth: 1,
  theme: theme
};
Card.displayName = 'Card';

function _templateObject$b() {
  var data = taggedTemplateLiteral(["\n  margin-left: auto;\n  margin-right: auto;\n\n  ", ";\n"]);

  _templateObject$b = function _templateObject() {
    return data;
  };

  return data;
}

var maxWidth = function maxWidth(props) {
  return props.maxWidth ? {
    maxWidth: "".concat(props.maxWidth, "px")
  } : {
    maxWidth: props.theme.maxContainerWidth
  };
};

var Container = styled.div(_templateObject$b(), maxWidth);
Container.propTypes = {
  maxWidth: PropTypes.number
};
Container.defaultProps = {
  theme: theme
};
Container.displayName = 'Container';

function _templateObject$c() {
  var data = taggedTemplateLiteral(["\n  border: 0;\n  border-bottom-style: solid;\n  border-bottom-width: 1px;\n  ", " ", " ", ";\n"]);

  _templateObject$c = function _templateObject() {
    return data;
  };

  return data;
}
var Divider = styled.hr(_templateObject$c(), space, width, borderColor);
Divider.displayName = 'Divider';
Divider.defaultProps = {
  borderColor: 'borderGray',
  theme: theme,
  ml: 0,
  mr: 0
};
Divider.propTypes = objectSpread({}, space.propTypes, width.propTypes, borderColor.propTypes);

function _templateObject$d() {
  var data = taggedTemplateLiteral(["\n  ", " ", " ", " ", " ", " ", ";\n"]);

  _templateObject$d = function _templateObject() {
    return data;
  };

  return data;
}

var getMaxWidth = function getMaxWidth(em) {
  return parseInt(em) - 0.01;
};

var breakpoints$1 = function breakpoints$$1(props) {
  return {
    xs: "@media screen and (max-width: ".concat(getMaxWidth(props.theme.breakpoints[0]), "em)"),
    sm: "@media screen and (min-width: ".concat(props.theme.breakpoints[0], ") and (max-width: ").concat(getMaxWidth(props.theme.breakpoints[1]), "em)"),
    md: "@media screen and (min-width: ".concat(props.theme.breakpoints[1], ") and (max-width: ").concat(getMaxWidth(props.theme.breakpoints[2]), "em)"),
    lg: "@media screen and (min-width: ".concat(props.theme.breakpoints[2], ") and (max-width: ").concat(getMaxWidth(props.theme.breakpoints[3]), "em)"),
    xl: "@media screen and (min-width: ".concat(props.theme.breakpoints[3], ")"),
    print: "@media print"
  };
};

var hidden = function hidden(key) {
  return function (props) {
    return props[key] ? defineProperty({}, breakpoints$1(props)[key], {
      display: 'none'
    }) : null;
  };
};

var Hide = styled(Box)(_templateObject$d(), hidden('xs'), hidden('sm'), hidden('md'), hidden('lg'), hidden('xl'), hidden('print'));
Hide.propTypes = {
  xs: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  xl: PropTypes.bool,
  print: PropTypes.bool
};
Hide.defaultProps = {
  theme: theme
};
Hide.displayName = 'Hide';

function _templateObject4() {
  var data = taggedTemplateLiteral(["\n  position: relative;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = taggedTemplateLiteral(["\n  font-size: ", "px;\n  border-radius: 0 0 ", " 0;\n  ", " ", ";\n  z-index: 2;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = taggedTemplateLiteral(["\n  flex: none;\n  background-color: ", ";\n  border-radius: 0 ", " ", " 0;\n  /* for 32 x 8 triangle */\n  transform: skew(-14deg);\n  position: relative;\n  z-index: 1;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject$e() {
  var data = taggedTemplateLiteral(["\n  width: 8px;\n  height: 8px;\n  align-self: flex-end;\n  ", ";\n  position: absolute;\n  bottom: 0;\n"]);

  _templateObject$e = function _templateObject() {
    return data;
  };

  return data;
}

var capitalize = function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

var shadowColor = function shadowColor(props) {
  var darkColor = themeGet("colors.dark".concat(capitalize(props.color)))(props);
  return {
    backgroundImage: !darkColor ? "\n        linear-gradient(45deg, transparent 50%, rgba(0, 0, 0, 0.5) 50%),\n        linear-gradient(45deg, transparent 50%, ".concat(props.color, " 50%)\n      ") : "linear-gradient(45deg, transparent 50%, ".concat(darkColor, " 50%)")
  };
};

var FlagShadow = styled(Box)(_templateObject$e(), shadowColor);
var FlagRight = styled(Box)(_templateObject2(), function (props) {
  return themeGet("colors.".concat(props.color), props.color)(props);
}, themeGet('radius'), themeGet('radius'));

var flexAuto = function flexAuto(props) {
  return props.flexAuto ? {
    flex: '1 1 auto'
  } : null;
};

var FlagBody = styled(Box)(_templateObject3(), themeGet('fontSizes.0'), themeGet('radius'), flexAuto, color);
var RelativeHide = styled(Hide)(_templateObject4());

var Flag = function Flag(_ref) {
  var color$$1 = _ref.color,
      bg = _ref.bg,
      children = _ref.children,
      width$$1 = _ref.width,
      props = objectWithoutProperties(_ref, ["color", "bg", "children", "width"]);

  return React.createElement(Flex, _extends_1({
    width: width$$1
  }, props, {
    ml: [0, -2]
  }), React.createElement(RelativeHide, {
    xs: true
  }, React.createElement(FlagShadow, {
    width: "4px",
    mr: -2,
    mb: -2,
    color: bg
  })), React.createElement(FlagBody, {
    flexAuto: !!width$$1,
    color: color$$1,
    bg: bg,
    py: [1, 2],
    pl: [1, 3]
  }, children), React.createElement(FlagRight, {
    width: "18px",
    color: bg,
    ml: -2
  }));
};

Flag.propTypes = objectSpread({}, color.propTypes);
Flag.defaultProps = {
  color: 'white',
  bg: 'green'
};
Flag.displayName = 'Flag';

function _templateObject$f() {
  var data = taggedTemplateLiteral(["\n  background-color: ", ";\n\n  &:hover {\n    background-color: ", ";\n  }\n"]);

  _templateObject$f = function _templateObject() {
    return data;
  };

  return data;
}
var GreenButton = styled(Button)(_templateObject$f(), function (props) {
  return props.theme.colors.green;
}, function (props) {
  return props.disabled ? null : props.theme.colors.darkGreen;
});
GreenButton.defaultProps = {
  theme: theme
};
GreenButton.displayName = 'GreenButton';

function _templateObject3$1() {
  var data = taggedTemplateLiteral(["\n  ", ";\n"]);

  _templateObject3$1 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2$1() {
  var data = taggedTemplateLiteral(["\n  & > * {\n    border: 0 !important;\n  }\n"]);

  _templateObject2$1 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject$g() {
  var data = taggedTemplateLiteral(["\n  border-top-left-radius: ", ";\n  border-top-right-radius: ", ";\n  border-bottom-right-radius: 0;\n  border-bottom-left-radius: 0;\n  overflow: hidden;\n"]);

  _templateObject$g = function _templateObject() {
    return data;
  };

  return data;
}
var HugCard = styled(Card)(_templateObject$g(), function (props) {
  return props.theme.radius;
}, function (props) {
  return props.theme.radius;
});
var BorderConcealer = styled(Box)(_templateObject2$1());
var HideableIcon = styled(Icon)(_templateObject3$1(), display);

var Hug = function Hug(_ref) {
  var bg = _ref.bg,
      color$$1 = _ref.color,
      p = _ref.p,
      fontSize$$1 = _ref.fontSize,
      icon = _ref.icon,
      iconDisplay = _ref.iconDisplay,
      props = objectWithoutProperties(_ref, ["bg", "color", "p", "fontSize", "icon", "iconDisplay"]);

  return React.createElement(HugCard, _extends_1({}, props, {
    borderColor: bg
  }), React.createElement(Flex, {
    bg: bg,
    color: color$$1,
    p: p,
    alignItems: "center"
  }, !!icon && React.createElement(HideableIcon, {
    mr: 2,
    mt: "-2px",
    mb: "2px",
    name: icon,
    size: 24,
    display: iconDisplay
  }), React.createElement(Text, {
    fontSize: fontSize$$1
  }, props.text)), React.createElement(BorderConcealer, null, props.children));
};

Hug.defaultProps = {
  bg: 'green',
  borderWidth: 1,
  color: 'white',
  fontSize: 1,
  p: 2,
  theme: theme
};
Hug.propTypes = {
  iconDisplay: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.string])
};

var IconField = function IconField(props) {
  var children = React.Children.toArray(props.children).filter(function (child) {
    return child.type.isField || child.type.isIcon;
  });
  var styledChildren = children.map(function (child, i) {
    if (child.type.isIcon) {
      return React.cloneElement(child, {
        style: objectSpread({}, child.props.style, {
          flex: 'none',
          alignSelf: 'center',
          pointerEvents: 'none',
          marginLeft: i === 0 ? 8 : -32,
          marginRight: i === 0 ? -32 : 8
        })
      });
    }

    return React.cloneElement(child, {
      style: objectSpread({}, child.props.style, {
        paddingLeft: i === 0 ? undefined : 40,
        paddingRight: i === children.length - 1 ? undefined : 40
      })
    });
  });
  return React.createElement(Flex, null, styledChildren);
};

function _templateObject$h() {
  var data = taggedTemplateLiteral(["\n  display: block;\n  max-width: 100%;\n  height: auto;\n"]);

  _templateObject$h = function _templateObject() {
    return data;
  };

  return data;
}
var Image = styled.img(_templateObject$h());
Image.displayName = 'Image';
Image.defaultProps = {
  theme: theme
};

function _templateObject$i() {
  var data = taggedTemplateLiteral(["\n  appearance: none;\n  display: block;\n  width: 100%;\n  font-family: inherit;\n  font-size: ", "px;\n  color: inherit;\n  background-color: transparent;\n  border-radius: ", ";\n  border-width: 0px;\n  border-style: solid;\n  border-color: ", ";\n\n  padding-top: 14px;\n  padding-bottom: 14px;\n  padding-left: 12px;\n  padding-right: 12px;\n\n  margin: 0;\n\n  ::placeholder {\n    color: ", ";\n  }\n\n  ::-ms-clear {\n    display: none;\n  }\n\n  ", " ", ";\n"]);

  _templateObject$i = function _templateObject() {
    return data;
  };

  return data;
}

var borders = function borders(_ref) {
  var color$$1 = _ref.color,
      theme$$1 = _ref.theme;
  var borderColor$$1 = color$$1 ? theme$$1.colors[color$$1] : theme$$1.colors.borderGray;
  var focusColor = color$$1 ? borderColor$$1 : theme$$1.colors.blue;
  return {
    'border-color': borderColor$$1,
    'box-shadow': "0 0 0 1px ".concat(borderColor$$1),
    ':focus': {
      outline: 0,
      'border-color': focusColor,
      'box-shadow': "0 0 0 2px ".concat(focusColor)
    }
  };
};

var Input = styled.input(_templateObject$i(), themeGet('fontSizes.1'), themeGet('radius'), themeGet('colors.borderGray'), themeGet('colors.gray'), borders, space);
Input.displayName = 'Input';
Input.isField = true;
Input.propTypes = objectSpread({
  id: PropTypes.string.isRequired,
  color: PropTypes.string
}, borders.propTypes, space.propTypes);
Input.defaultProps = {
  theme: theme
};

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var arrayWithHoles = _arrayWithHoles;

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

var iterableToArrayLimit = _iterableToArrayLimit;

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

var nonIterableRest = _nonIterableRest;

function _slicedToArray(arr, i) {
  return arrayWithHoles(arr) || iterableToArrayLimit(arr, i) || nonIterableRest();
}

var slicedToArray = _slicedToArray;

var FormField = function FormField(props) {
  var iconBefore = false;
  var children = React.Children.toArray(props.children);

  var _children$filter = children.filter(function (child) {
    return child.type.isField;
  }),
      _children$filter2 = slicedToArray(_children$filter, 1),
      field = _children$filter2[0];

  var _children$filter3 = children.filter(function (child) {
    return child.type.isLabel;
  }),
      _children$filter4 = slicedToArray(_children$filter3, 1),
      label = _children$filter4[0];

  var showLabel = label && !label.props.autoHide || field && !!field.props.value;
  var id = field && (field.props.id || field.props.name);
  var styled$$1 = children.map(function (child, i, arr) {
    if (child.type.isField && arr[i - 1] && arr[i - 1].type.isIcon) {
      iconBefore = true;
    }

    if (child === field) {
      return React.cloneElement(child, {
        id: id,
        style: objectSpread({}, child.props.style, {
          transitionProperty: 'padding-top, padding-bottom',
          transitionDuration: '.1s',
          paddingTop: showLabel ? 20 : 14,
          paddingBottom: showLabel ? 8 : 14
        })
      });
    }

    return child;
  });
  var styledLabel = label && React.cloneElement(label, {
    htmlFor: label.props.htmlFor || id,
    fontSize: 10,
    ml: iconBefore ? '40px' : '12px',
    pt: '6px',
    mb: '-20px',
    style: objectSpread({}, label.props.style, {
      height: 20,
      transitionProperty: 'opacity',
      transitionDuration: '.1s',
      opacity: showLabel ? 1 : 0
    })
  });
  return React.createElement("div", null, styledLabel, React.createElement(IconField, null, styled$$1));
};

var childrenPropType = function childrenPropType(props, propName, componentName) {
  var children = React.Children.toArray(props.children);

  var _children$filter5 = children.filter(function (child) {
    return child.type.isLabel;
  }),
      _children$filter6 = slicedToArray(_children$filter5, 1),
      label = _children$filter6[0];

  var _children$filter7 = children.filter(function (child) {
    return child.type.isField;
  }),
      _children$filter8 = slicedToArray(_children$filter7, 1),
      field = _children$filter8[0];

  if (!field) {
    return new Error("No form field found for ".concat(componentName, ". Please include an Input, Select, or other form field as a child."));
  }

  if (!label) {
    return new Error("No label found for ".concat(componentName, ". Please include a Label as a child."));
  }
};

FormField.propTypes = {
  children: childrenPropType
};
FormField.displayName = 'FormField';

function _templateObject$j() {
  var data = taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  border-radius: ", ";\n  border-width: 1px;\n  border-style: solid;\n  ", "\n  ", "\n\n  & > ", " {\n    width: 100%;\n    flex: 1 1 auto;\n  }\n\n  & ", " {\n    border: 0;\n    box-shadow: none;\n  }\n"]);

  _templateObject$j = function _templateObject() {
    return data;
  };

  return data;
}
var InputGroup = styled.div(_templateObject$j(), themeGet('radius'), borderColor, space, Box, Input);
InputGroup.propTypes = objectSpread({}, space.propTypes, borderColor.propTypes);
InputGroup.defaultProps = {
  theme: theme,
  borderColor: 'borderGray'
};

function _templateObject$k() {
  var data = taggedTemplateLiteral(["\n  font-size: 10px;\n  letter-spacing: 0.2px;\n  display: block;\n  width: 100%;\n  margin: 0;\n\n  ", " ", " ", " ", ";\n  ", "\n  ", "\n"]);

  _templateObject$k = function _templateObject() {
    return data;
  };

  return data;
}

var nowrap = function nowrap(props) {
  return props.nowrap ? {
    whiteSpace: 'nowrap'
  } : null;
};

var accessiblyHide = function accessiblyHide(props) {
  return props.hidden ? {
    position: 'absolute',
    width: '1px',
    height: '1px',
    clip: 'rect(1px, 1px, 1px, 1px)'
  } : null;
};

var Label = styled.label(_templateObject$k(), space, fontSize, color, fontWeight, nowrap, accessiblyHide);
Label.propTypes = objectSpread({}, space.propTypes, fontSize.propTypes, color.propTypes, fontWeight.propTypes);
Label.defaultProps = {
  fontSize: '10px',
  fontWeight: 'bold',
  color: 'gray',
  theme: theme
};
Label.displayName = 'Label';
Label.isLabel = true;

function _templateObject$l() {
  var data = taggedTemplateLiteral(["\n  color: ", ";\n  box-shadow: inset 0 0 0 2px ", ";\n  background-color: transparent;\n\n  &:hover {\n    color: ", ";\n    box-shadow: inset 0 0 0 2px\n      ", ";\n    background-color: transparent;\n  }\n"]);

  _templateObject$l = function _templateObject() {
    return data;
  };

  return data;
}
var OutlineButton = styled(Button)(_templateObject$l(), function (props) {
  return props.theme.colors.blue;
}, function (props) {
  return props.theme.colors.blue;
}, function (props) {
  return props.disabled ? null : props.theme.colors.darkBlue;
}, function (props) {
  return props.disabled ? null : props.theme.colors.darkBlue;
});
OutlineButton.defaultProps = {
  theme: theme
};
OutlineButton.displayName = 'OutlineButton';

function _templateObject3$2() {
  var data = taggedTemplateLiteral(["\n  vertical-align: middle;\n"]);

  _templateObject3$2 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2$2() {
  var data = taggedTemplateLiteral(["\n  appearance: none;\n  opacity: 0;\n  position: absolute;\n  z-index: 0;\n  &:focus {\n    box-shadow: none;\n  }\n  &:checked ~ svg {\n    color: ", ";\n  }\n  &:disabled ~ svg {\n    color: ", ";\n  }\n"]);

  _templateObject2$2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject$m() {
  var data = taggedTemplateLiteral(["\n  display: inline-block;\n  color: ", ";\n  &:hover {\n    ", ";\n  }\n"]);

  _templateObject$m = function _templateObject() {
    return data;
  };

  return data;
}
var RadioWrap = styled.div(_templateObject$m(), function (props) {
  return props.theme.colors.borderGray;
}, function (props) {
  return props.checked || props.disabled ? null : "color: ".concat(props.theme.colors.blue, ";");
});
var RadioInput = styled.input(_templateObject2$2(), function (props) {
  return props.theme.colors.blue;
}, function (props) {
  return props.theme.colors.borderGray;
});
var RadioIcon = styled(Icon)(_templateObject3$2());

var Radio = function Radio(props) {
  var checked = props.checked,
      disabled = props.disabled;
  var radioIconName = checked ? 'radioChecked' : 'radioEmpty';
  return React.createElement(RadioWrap, {
    checked: checked,
    disabled: disabled
  }, React.createElement(RadioInput, _extends_1({
    type: "radio"
  }, props)), React.createElement(RadioIcon, {
    name: radioIconName,
    size: 24
  }));
};

Radio.defaultProps = {
  theme: theme
};

function _templateObject$n() {
  var data = taggedTemplateLiteral(["\n  display: inline-block;\n  line-height: 1.5;\n  ", " ", ";\n"]);

  _templateObject$n = function _templateObject() {
    return data;
  };

  return data;
}
var RatingBadge = styled(Box)(_templateObject$n(), fontWeight, borderRadius);
RatingBadge.defaultProps = {
  fontWeight: 'bold',
  px: 2,
  color: 'white',
  bg: 'orange',
  borderRadius: 1,
  theme: theme
};
RatingBadge.propTypes = objectSpread({}, fontWeight.propTypes, borderRadius.propTypes);

function _templateObject$o() {
  var data = taggedTemplateLiteral(["\n  background-color: ", ";\n\n  &:hover {\n    background-color: ", ";\n  }\n"]);

  _templateObject$o = function _templateObject() {
    return data;
  };

  return data;
}
var RedButton = styled(Button)(_templateObject$o(), function (props) {
  return props.theme.colors.red;
}, function (props) {
  return props.disabled ? null : props.theme.colors.darkRed;
});
RedButton.defaultProps = {
  theme: theme
};

function _templateObject$p() {
  var data = taggedTemplateLiteral(["\n  position: relative;\n  ", " ", " ", " ", "\n  ", "\n"]);

  _templateObject$p = function _templateObject() {
    return data;
  };

  return data;
}
var Relative = styled(Box)(_templateObject$p(), top, bottom, left, right, zIndex);
Relative.propTypes = objectSpread({}, top.propTypes, right.propTypes, bottom.propTypes, left.propTypes, zIndex.propTypes);
Relative.displayName = 'Relative';

function _templateObject$q() {
  var data = taggedTemplateLiteral(["\n  display: inline-flex;\n  align-items: center;\n  vertical-align: top;\n  min-height: 24px;\n  font-weight: 600;\n  text-transform: uppercase;\n  letter-spacing: ", ";\n  border-radius: 2px;\n  border-width: 1px;\n  border-style: solid;\n  border-color: ", ";\n  ", " ", " ", ";\n"]);

  _templateObject$q = function _templateObject() {
    return data;
  };

  return data;
}
var Stamp = styled.div(_templateObject$q(), themeGet('letterSpacings.caps'), function (props) {
  return theme.colors.borderGray;
}, space, fontSize, color);
Stamp.displayName = 'Stamp';
Stamp.propTypes = objectSpread({}, space.propTypes, fontSize.propTypes, color.propTypes);
Stamp.defaultProps = {
  px: 1,
  py: 0,
  theme: theme,
  color: 'gray',
  bg: 'lightGray',
  fontSize: 0
};

function _templateObject3$3() {
  var data = taggedTemplateLiteral([""]);

  _templateObject3$3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2$3() {
  var data = taggedTemplateLiteral(["\n  appearance: none;\n  display: block;\n  width: 100%;\n  font-family: inherit;\n  color: inherit;\n  background-color: transparent;\n  border-radius: ", ";\n  border-width: 1px;\n  border-style: solid;\n  border-color: ", ";\n  ", " ", " &:focus {\n    outline: none;\n    border-color: ", ";\n    box-shadow: 0 0 0 1px ", ";\n  }\n"]);

  _templateObject2$3 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject$r() {
  var data = taggedTemplateLiteral(["\n  pointer-events: none;\n"]);

  _templateObject$r = function _templateObject() {
    return data;
  };

  return data;
}
var ClickableIcon = styled(Icon)(_templateObject$r());
var SelectBase = styled.select(_templateObject2$3(), themeGet('radius'), themeGet('colors.borderGray'), space, fontSize, themeGet('colors.blue'), themeGet('colors.blue'));
SelectBase.defaultProps = {
  theme: theme,
  fontSize: 1,
  m: 0,
  pl: 12,
  pr: 32,
  py: 14
};
SelectBase.propTypes = objectSpread({}, space.propTypes, fontSize.propTypes);
var Select = styled(function (props) {
  return React.createElement(Flex, {
    width: 1,
    alignItems: "center"
  }, React.createElement(SelectBase, props), React.createElement(ClickableIcon, {
    ml: -32,
    name: "chevronDown",
    color: "gray"
  }));
})(_templateObject3$3());
Select.isField = true;

function _templateObject$s() {
  var data = taggedTemplateLiteral(["\n  display: inline;\n  box-shadow: ", ";\n  font-size: ", "px;\n  position: absolute;\n  border-radius: ", "px;\n  box-sizing: border-box;\n  background: ", ";\n  text-align: center;\n\n  ", " ", " &::after {\n    content: '';\n    position: absolute;\n    width: 0;\n    height: 0;\n    border-width: 5px;\n    border-style: solid;\n    border-color: transparent transparent ", "\n      ", ";\n\n    ", " ", " ", " ", ";\n  }\n"]);

  _templateObject$s = function _templateObject() {
    return data;
  };

  return data;
}

var arrowShadow = function arrowShadow(props) {
  return props.top ? {
    'box-shadow': '-9.66px 9.66px 8px 0 rgba(0,0,0,0.04), -4px 4px 4px 0 rgba(0,0,0,0.08)'
  } : {
    'box-shadow': '-1.41px 1.41px 1px 0 rgba(0,0,0,0.01), -3.66px 3.66px 8px 0 rgba(0,0,0,0.04)'
  };
};

var arrowAlign = function arrowAlign(props) {
  return props.left ? {
    left: '16px',
    'margin-left': props.top ? 0 : '15px'
  } : props.center ? {
    left: '50%',
    'margin-left': props.top ? '-7px' : '7px'
  } : {
    right: '16px',
    'margin-right': props.top ? '5px' : '-10px'
  };
};

var arrowPosition = function arrowPosition(props) {
  return props.top ? {
    'transform-origin': '0 0',
    transform: 'rotate(-45deg)',
    bottom: '-10px'
  } : {
    'transform-origin': '0 0',
    transform: 'rotate(-225deg)',
    top: '0'
  };
};

var arrow = function arrow(props) {
  return props.top ? {
    'transform-origin': '0 0',
    transform: 'rotate(-45deg)'
  } : {
    'transform-origin': '0 0',
    transform: 'rotate(-225deg)'
  };
};

var tooltipPosition = function tooltipPosition(props) {
  return props.top ? {
    bottom: '-8px'
  } : {
    top: 0
  };
};

var tooltipAlign = function tooltipAlign(props) {
  return props.right ? {
    right: 0
  } : props.center ? {
    left: '50%',
    width: 'auto',
    transform: 'translateX(-50%)'
  } : null;
};

var TooltipContent = styled(Box)(_templateObject$s(), function (_ref) {
  var theme$$1 = _ref.theme;
  return theme$$1.boxShadows[1];
}, function (_ref2) {
  var theme$$1 = _ref2.theme;
  return theme$$1.fontSizes[0];
}, function (_ref3) {
  var theme$$1 = _ref3.theme;
  return theme$$1.radii[1];
}, function (_ref4) {
  var theme$$1 = _ref4.theme,
      bg = _ref4.bg;
  return theme$$1.colors[bg];
}, tooltipPosition, tooltipAlign, function (_ref5) {
  var theme$$1 = _ref5.theme,
      bg = _ref5.bg;
  return theme$$1.colors[bg];
}, function (_ref6) {
  var theme$$1 = _ref6.theme,
      bg = _ref6.bg;
  return theme$$1.colors[bg];
}, arrow, arrowPosition, arrowAlign, arrowShadow);
var propTypes = {
  children: PropTypes.any.isRequired,
  bg: PropTypes.string,
  color: PropTypes.string,
  bottom: PropTypes.bool,
  top: PropTypes.bool,
  center: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  zIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
var defaultProps = {
  position: 'bottom',
  color: 'text',
  bg: 'white',
  theme: theme,
  zIndex: 'auto'
};

var Tooltip = function Tooltip(_ref7) {
  var children = _ref7.children,
      align = _ref7.align,
      props = objectWithoutProperties(_ref7, ["children", "align"]);

  return React.createElement("div", {
    style: {
      position: 'relative',
      zIndex: props.zIndex
    }
  }, React.createElement(TooltipContent, _extends_1({
    p: 2,
    mb: 3,
    mt: 2
  }, props), children));
};

Tooltip.propTypes = propTypes;
Tooltip.defaultProps = defaultProps;

function _templateObject$t() {
  var data = taggedTemplateLiteral(["\n  border-radius: ", ";\n  border: 0;\n  display: inline-block;\n  font-weight: ", ";\n  font-family: inherit;\n  cursor: pointer;\n  background-color: ", ";\n  color: ", ";\n  ", " ", ";\n  &:hover {\n    background-color: ", ";\n  }\n"]);

  _templateObject$t = function _templateObject() {
    return data;
  };

  return data;
}
var ToggleBadge = styled.button(_templateObject$t(), function (props) {
  return props.theme.radius;
}, function (props) {
  return props.theme.bold;
}, function (props) {
  return props.selected ? props.theme.colors[props.bg] : props.unSelectedBg;
}, function (props) {
  return props.theme.colors[props.color];
}, space, fontSize, function (props) {
  return props.theme.colors[props.bg];
});
ToggleBadge.displayName = 'ToggleBadge';
ToggleBadge.propTypes = objectSpread({
  selected: PropTypes.bool
}, space.propTypes, fontSize.propTypes);
ToggleBadge.defaultProps = {
  selected: false,
  px: 2,
  py: 1,
  mx: 1,
  my: 1,
  fontSize: 0,
  theme: theme,
  color: 'blue',
  bg: 'lightBlue',
  unSelectedBg: 'transparent'
};

function _templateObject$u() {
  var data = taggedTemplateLiteral(["\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n"]);

  _templateObject$u = function _templateObject() {
    return data;
  };

  return data;
}
var Truncate = styled(Text)(_templateObject$u());
Truncate.defaultProps = {
  theme: theme
};
Truncate.displayName = 'Truncate';

function _templateObject2$4() {
  var data = taggedTemplateLiteral(["\n  appearance: none;\n  opacity: 0;\n  position: absolute;\n  z-index: 0;\n"]);

  _templateObject2$4 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject$v() {
  var data = taggedTemplateLiteral(["\n  display: inline-block;\n  position: relative;\n  vertical-align: middle;\n  cursor: pointer;\n  color: ", ";\n\n  svg[data-name='checked'] {\n    display: none;\n  }\n\n  > input:checked {\n    & ~ svg[data-name='checked'] {\n      display: inline-block;\n      color: ", ";\n    }\n\n    & ~ svg[data-name='empty'] {\n      display: none;\n    }\n  }\n"]);

  _templateObject$v = function _templateObject() {
    return data;
  };

  return data;
}

var Checkbox = function Checkbox(props) {
  var disabled = props.disabled,
      size = props.size;
  return React.createElement(CheckBoxWrapper, {
    disabled: disabled
  }, React.createElement(StyledInput, _extends_1({
    type: "checkbox"
  }, props)), React.createElement(Icon, {
    name: "boxChecked",
    size: size,
    "data-name": "checked"
  }), React.createElement(Icon, {
    name: "boxEmpty",
    size: size,
    "data-name": "empty"
  }));
};

var CheckBoxWrapper = styled(Box)(_templateObject$v(), function (props) {
  return props.disabled ? props.theme.colors.borderGray : props.theme.colors.gray;
}, function (props) {
  return props.disabled ? props.theme.colors.borderGray : props.theme.colors.blue;
});
CheckBoxWrapper.defaultProps = {
  theme: theme
};
var StyledInput = styled.input(_templateObject2$4());
Checkbox.displayName = 'Checkbox';
Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.number,
  onChange: PropTypes.func.isRequired
};
Checkbox.defaultProps = {
  size: 20
};

function _templateObject$w() {
  var data = taggedTemplateLiteral(["\n  font-family: ", ";\n  line-height: ", ";\n  font-weight: ", ";\n\n  * {\n    box-sizing: border-box;\n  }\n"]);

  _templateObject$w = function _templateObject() {
    return data;
  };

  return data;
}
var Base = styled.div(_templateObject$w(), function (props) {
  return props.theme.font;
}, function (props) {
  return props.theme.lineHeights.standard;
}, function (props) {
  return props.theme.fontWeights.medium;
});

var ThemeProvider$1 = function ThemeProvider$$1(_ref) {
  var customBreakpoints = _ref.customBreakpoints,
      props = objectWithoutProperties(_ref, ["customBreakpoints"]);

  var breakpoints$$1 = customBreakpoints || theme.breakpoints;

  var theme$$1 = objectSpread({}, theme, {
    breakpoints: breakpoints$$1
  });

  return React.createElement(ThemeProvider, {
    theme: theme$$1
  }, React.createElement(Base, props));
};

ThemeProvider$1.propTypes = {
  /** Array of pixel values for custom breakpoint overrides */
  customBreakpoints: PropTypes.arrayOf(PropTypes.number)
};

export { Absolute, BackgroundImage, Badge, Banner, BlockLink, Box, Button, Card, CloseButton, Container, Divider, Flag, Flex, GreenButton, Heading, Hide, Hug, Icon, IconButton, IconField, Image, Input, FormField as InputField, FormField, InputGroup, Label, Link, OutlineButton, Radio, RatingBadge, RedButton, Relative, Stamp, Select, Text, Tooltip, ToggleBadge, Truncate, Checkbox, theme, ThemeProvider$1 as ThemeProvider, breakpoints, mediaQueries, space$1 as space, font, fontSizes, medium, bold, regular, fontWeights, lineHeights, textStyles, colors, colorStyles, radii, radius, maxContainerWidth, boxShadows, duration };
